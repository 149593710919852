<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-select :items="documents"
                      filled
                      label="Документ"
                      v-model="document"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="document ? '' : 'req-star'"
                      @change="onDocumentChange"
                      color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-switch label="Стандартний номер"
                      hide-details
                      color="success"
                      class="mt-0 pt-0"
                      v-model="document_settings.default_number"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-select :items="periods"
                      filled
                      label="Період нумерації документа"
                      v-model="document_settings.number_period"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="document_settings.number_period ? '' : 'req-star'"
                      color="grey"/>
          </v-col>
          <v-col cols="12">
            <v-text-field
                filled
                hide-details
                :disabled="document_settings.default_number"
                v-model="document_settings.number_template"
                placeholder="(person_id)|(contract_number)|(document_count)|(year)|(day)|(month)"
                hint="(person_id)|(contract_number)|(document_count)|(year)|(day)|(month)"
                color="grey"
                label="Шаблон формування номера"
            />
          </v-col>
          <template v-if="document === 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME'">
            <v-col cols="12" md="6">
              <v-switch label="Виводити баланс на початок періоду"
                        hide-details
                        color="success"
                        class="mt-0 pt-0"
                        v-model="document_settings.show_balance_on_start"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-switch label="Виводити баланс на кінець періоду"
                        hide-details
                        color="success"
                        class="mt-0 pt-0"
                        v-model="document_settings.show_balance_on_end"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-switch label="Виводити оплати"
                        hide-details
                        color="success"
                        class="mt-0 pt-0"
                        v-model="document_settings.show_pay"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-switch label="Виводити перерахунки/зняття"
                        hide-details
                        color="success"
                        class="mt-0 pt-0"
                        v-model="document_settings.show_recalculation"/>
            </v-col>
          </template>
          <template v-if="document === 'DOCUMENT_BILL_OUTCOME'">
            <v-col cols="12" md="6">
              <v-switch label="Виводити баланс на початок періоду"
                        hide-details
                        color="success"
                        class="mt-0 pt-0"
                        v-model="document_settings.show_balance_on_start"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-switch label="Виводити баланс на кінець періоду"
                        hide-details
                        color="success"
                        class="mt-0 pt-0"
                        v-model="document_settings.show_balance_on_end"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-switch label="Виводити оплати"
                        hide-details
                        color="success"
                        class="mt-0 pt-0"
                        v-model="document_settings.show_pay"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-switch label="Виводити перерахунки/зняття"
                        hide-details
                        color="success"
                        class="mt-0 pt-0"
                        v-model="document_settings.show_recalculation"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-switch label="Виводити підписи"
                        hide-details
                        color="success"
                        class="mt-0 pt-0"
                        v-model="document_settings.show_signature"/>
            </v-col>
          </template>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import legacyDocumentsAPI from "@/utils/axios/legacy_documents"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'legacy_document_settings_modal_delete'

export default {
  name: "HWP_Modal_LegacyDocumentSetting",
  mixins: [ModalComponentMixin],
  data() {
    return {
      documents: [
        {text: 'Акт виконаних робіт/наданих послуг', value: 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME'},
        {text: 'Рахунок за надані послуги/виконані роботи', value: 'DOCUMENT_BILL_OUTCOME'},
        {text: 'Податкова накладна (вихідна)', value: 'DOCUMENT_TAX_BILL_OUTCOME'},
      ],
      document: this.item.document,
      periods: [
        {text: 'Без періоду', value: 'ALL'},
        {text: 'Рік', value: 'YEAR'},
        {text: 'Місяць', value: 'MONTH'},
        {text: 'День', value: 'DAY'},
      ],
      settings: this.item.settings,
      document_settings_default: {
        DOCUMENT_ACT_WORK_PERFORMED_OUTCOME: {
          default_number: true,
          number_period: 'ALL',
          number_template: null,
          show_balance_on_start: false,
          show_pay: false,
          show_recalculation: false,
          show_balance_on_end: false,
        },
        DOCUMENT_BILL_OUTCOME: {
          default_number: true,
          number_period: 'ALL',
          number_template: null,
          show_balance_on_start: false,
          show_pay: false,
          show_recalculation: false,
          show_balance_on_end: false,
          show_signature: false,
        },
        DOCUMENT_TAX_BILL_OUTCOME: {
          default_number: true,
          number_period: 'ALL',
          number_template: null,
        },
      },
      document_settings: {},
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.document = this.item.document

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення налаштування',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Назва має бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        document_name: this.document,
        settings_schema: this.document_settings
      }
      if (!this.isNew) {
        payload.id = this.itemId
      }

      legacyDocumentsAPI.save_settings(payload)
          .then(el => {
            if (el) {
              this.closeModal()
            }
          })
    },
    onDocumentChange(payload) {
      const old = Object.assign({}, this.document_settings)
      let new_setting
      if (payload) {
        new_setting = Object.assign({}, this.document_settings_default[payload])
      } else {
        new_setting = Object.assign({}, this.document_settings_default.DOCUMENT_BILL_OUTCOME)
      }

      if (old) {
        Object.keys(old).forEach(i => {
          if (i in new_setting) {
            new_setting[i] = old[i]
          }
        })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.document = payload.document
              if (!payload) {
                this.document_settings = Object.assign({}, this.document_settings_default.DOCUMENT_BILL_OUTCOME)
              } else {
                if (payload.document) {
                  this.document_settings = Object.assign({}, this.document_settings_default[payload.document])

                  if (this.settings) {
                    const settings_parsed = JSON.parse(this.settings)
                    Object.keys(settings_parsed).forEach(i => {
                      if (i in this.document_settings) {
                        this.document_settings[i] = settings_parsed[i]
                      }
                    })
                  }

                } else {
                  this.document_settings = Object.assign({}, this.document_settings_default.DOCUMENT_BILL_OUTCOME)
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  legacyDocumentsAPI.remove_settings({ document_id: this.itemId })
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
